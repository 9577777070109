import React, { CSSProperties, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'gatsby';
import { Navbar, Nav, Row, Col, Button, Collapse, Container } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from 'react-icons-kit';
import { androidClose } from 'react-icons-kit/ionicons/androidClose';
import classNames from 'classnames';

import { color, images, spacing } from '../theme';
import { useMst } from '../../models/root-store';

import useSticky from './useSticky';

interface HeaderProps {
  siteTitle?: string;
  menuLinks: Array<{
    name: string;
    link: string;
  }>;
}

const ButtonLanguage = styled.button`
  padding-left: ${spacing[2]}px;
  padding-right: ${spacing[2]}px;
  background-color: transparent;
  border: none;
  font-size: 11px;
  color: '#000000';
`;

const NavbarContent = styled(Navbar)`
  background-color: #ffffff;
  align-items: initial !important;
  @media (min-width: 768px) {
    padding-bottom: ${spacing[6]}px !important;
    flex-flow: column nowrap !important;
    position: absolute !important;
    width: 100% !important;
  }
`;

const LOGO: CSSProperties = {
  height: 120,
};

const LINK: CSSProperties = {
  display: 'block',
  paddingLeft: spacing[4],
  paddingRight: spacing[4],
  textDecoration: 'none',
  color: '#000000',
};

const MINI_BUTTON_NAV: CSSProperties = {
  display: 'flex',
  alignItems: 'flex-start',
  position: 'absolute',
  zIndex: 9999,
  top: 0,
  bottom: 0,
  marginTop: 10,
};

const shrinkOn = 40;

const Header: React.FC<HeaderProps> = observer(({ siteTitle, menuLinks }) => {
  const { t, i18n } = useTranslation();
  const { ouayUnStore } = useMst();
  const [values, setValues] = useState({ language: 'th' });
  const [navExpanded, setNavExpanded] = useState(false);
  const [scrollY, setScrollY] = useState<number>(0);

  const { sticky, stickyRef } = useSticky();

  useEffect(() => {
    const handleScroll = () => {
      const distanceY = window.scrollY || document.documentElement.scrollTop;
      setScrollY(distanceY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [setScrollY]);

  useEffect(() => {
    const { language } = ouayUnStore;
    setValues({ language });
  }, [ouayUnStore.language]);

  const handleChange = (lang: string) => {
    i18n.changeLanguage(lang);
    ouayUnStore.getLanguage(lang);
    setValues({ language: lang });
  };

  const NAVBAR_ITEM: CSSProperties = {
    fontSize: values.language === 'th' ? 17 : 15,
    fontFamily: values.language === 'th' ? 'KanitLight' : 'RobotaRegular',
    textDecoration: 'none',
  };

  return (
    <NavbarContent expand="md" expanded={navExpanded} fixed="top">
      {/* //? language screen for md++ size */}
      <Row
        className="justify-content-end"
        style={{
          position: ouayUnStore?.screenSize?.width < 768 ? 'relative' : 'absolute',
          right: 15,
          zIndex: 999,
        }}
      >
        <Col md="auto" className="d-none d-md-block">
          <Nav className="ml-auto">
            <Row>
              <Col>
                <ButtonLanguage
                  style={{
                    fontFamily:
                      values.language === 'th'
                        ? 'RobotaBlack'
                        : 'RobotaRegular',
                  }}
                  onClick={() => handleChange('th')}
                >
                  <LabelLanguage>TH</LabelLanguage>
                </ButtonLanguage>
                <ButtonLanguage
                  style={{
                    fontFamily:
                      values.language === 'en'
                        ? 'RobotaBlack'
                        : 'RobotaRegular',
                  }}
                  onClick={() => handleChange('en')}
                >
                  <LabelLanguage>EN</LabelLanguage>
                </ButtonLanguage>
              </Col>
            </Row>
          </Nav>
        </Col>
      </Row>
      <Row
        id="small-header"
        className={'header-smaller justify-content-md-center optimize-header'}
        style={{ margin: 0 }}
      >
        <ColHidden xs="auto">
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            style={{ padding: '3px 6px', border: 'none' }}
            onClick={() => setNavExpanded(!navExpanded)}
          />
        </ColHidden>
        <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Navbar.Brand style={{ marginRight: 0 }}>
            <Link
              to={'/'}
              style={{ display: 'flex' }}
              onClick={() => setNavExpanded(false)}
            >
              <Image
                src={images.logo}
                alt="logo"
                maxHeight={scrollY > shrinkOn ? 59 : 120}
                marginTop={scrollY > shrinkOn ? -5 : -3}
              />
            </Link>
          </Navbar.Brand>
        </Col>
      </Row>
      <Navbar.Collapse
        id="basic-navbar-nav-collapse"
        className="justify-content-center"
      >
        {/* //? for screen size xs-md */}

        <Container className="d-md-none d-block" fluid style={{ backgroundColor: color.backgroundPrimary, }}>
          <div className="not-footer-hd">
            <div
              style={{
                width: '100%',
                padding: `${spacing[7]}px 0 0px`,
                height: '90vh',
                position: 'relative',
              }}
            >
              <Row className="justify-content-center">
                <Col xs="10" sm="8">
                  {menuLinks.map((link: any, index: number) => (
                    <Nav.Item
                      key={`${link}-${index}`}
                      className="nav-items-small"
                    >
                      <Link
                        to={link.link}
                        activeStyle={{
                          color: color.mainColor4,
                        }}
                        partiallyActive
                        onClick={() => setNavExpanded(false)}
                      >
                        <span style={NAVBAR_ITEM}>{link.name}</span>
                      </Link>
                    </Nav.Item>
                  ))}
                </Col>
              </Row>

              <Row className="justify-content-center" style={{ minHeight: 56 }}>
                <Col
                  xs="auto"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <ButtonLanguage
                    style={{
                      ...NAVBAR_ITEM,
                      borderRight: `1px solid ${color.mainColor5}`,
                      paddingRight: 20
                    }}
                    onClick={() => handleChange('th')}
                  >
                    <LabelLanguage>TH</LabelLanguage>
                  </ButtonLanguage>
                  <ButtonLanguage
                    style={{
                      ...NAVBAR_ITEM,
                      paddingLeft: 20
                    }}
                    onClick={() => handleChange('en')}
                  >
                    <LabelLanguage>EN</LabelLanguage>
                  </ButtonLanguage>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col xs="auto" style={{ paddingBottom: spacing[6] }}>
                  <IconButton
                    aria-label="delete"
                    disableRipple
                    onClick={() => setNavExpanded(false)}
                  >
                    <Icon
                      icon={androidClose}
                      size={25}
                      style={{ color: '#dfdfdf' }}
                    />
                  </IconButton>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
        {/* //? for screen size md++ */}
        <Row className="d-none d-md-block" style={{ width: '100%' }}>
          <Col md="auto" ref={stickyRef}>
            <Nav className={classNames('nav-sticky flex', { sticky })}>
              {menuLinks.map((link: any, index: number) => (
                <Nav.Item
                  style={{
                    textAlign: 'center',
                    // borderBottom: `1px solid ${color.mainColor5}`,
                  }}
                  key={`${link}-${index + 1}`}
                >
                  <Link
                    style={{
                      ...LINK,
                      padding: `${spacing[2]}px ${spacing[4]}px`,
                    }}
                    to={link.link}
                    activeStyle={{
                      color: color.mainColor4,
                    }}
                    partiallyActive
                  >
                    <span style={NAVBAR_ITEM}>{link.name}</span>
                  </Link>
                </Nav.Item>
              ))}
            </Nav>

            {sticky && (
              <div
                style={{
                  height: `${stickyRef.current?.clientHeight}px`,
                }}
              />
            )}
          </Col>
        </Row>
      </Navbar.Collapse>
    </NavbarContent>
  );
});

export default Header;

const Image = styled.img<{ maxHeight?: number, marginTop?: number }>`
  height: 120px;
  @media (max-width: 768px) {
    max-width: ${(props) => props?.maxHeight || 120}px;
    margin-top: ${(props) => props?.marginTop || 0}px;
    height: auto;
    transition: all 0.3s;
  }
`

const ColHidden = styled(Col)`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    align-items: flex-start;
    position: absolute !important;
    z-index: 9999;
    top: 0;
    bottom: 0;
    margin-top: 10;
  }
`

const LabelLanguage = styled.span`
  color: #000;
`
