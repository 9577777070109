import React from 'react';
import '../Layouts/css/main.css';
import '../Layouts/css/header.css';
import { withTrans } from '../i18n/withTrans';
import MainLayout from './main-layout';
import { Provider, rootStore } from '../models/root-store';
import { ParallaxProvider } from 'react-scroll-parallax';

const LayoutPage: React.FC<{
  pageContext: { layout: string };
  custom404: any;
  location: any;
}> = (props) => {
  return (
    <Provider value={rootStore}>
      <ParallaxProvider>
        <MainLayout {...props} />
      </ParallaxProvider>
    </Provider>
  );
};

export default withTrans(LayoutPage);
