/**
 * NOTE TO DEVS:
 *
 * Spacing should be consistent and whitespace thought of as a first class technique up
 * there with color and typefaces.
 *
 * Which type of scale you use is based on the design.
 *
 * If you've got simpler app, you may only need 6 items.  Or maybe you want a spacing scale
 * to be named:
 *
 * export const spacing = {
 *   tiny: 4,
 *   small: 8,
 *   medium: 12,
 *   large: 24,
 *   huge: 64
 * }
 *
 * Whatever you choose, try to stick with these, and not freestyle it everywhere.
 *
 * Feel free to delete this block.
 */

/**
 * The available spacing.
 *
 * Here's the rough guideline.  Customize this for you usage.  It's ok to put exceptions
 * within the components themselves if they are truly exceptions.
 *
 * 0 = none (0)           - nothing. only here to bust out of a zero-based array.
 * 1 = tiny (4)           - elements contextually close to each other
 * 2 = smaller (8)        - for groups of closely related items or perhaps borders
 * 3 = small (12)         - ?
 * 4 = medium (16)        - ?
 * 5 = medium+ (25)       - between content and button
 * 6 = large (30)         - between groups of content
 * 7 = huge (50)          - ?
 * 8 = massive (65)       - between each group of content
 * 9 = massive+ (100)     - space between saction
 * 10 = massive++ (144)   - image border-radius
 */
export const spacing = [0, 4, 8, 12, 16, 25, 30, 50, 65, 100, 144];
