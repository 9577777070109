export const images = {
  logo: require('../../images/main/Logo-Ouayun-Rollover.png').default,
  logoFooter: require('../../images/main/Asset_Logo_Footer_2.svg').default,
  // mainSlide1: require('../../images/slide/main-slide-1.jpg').default,
  mainSlide2: require('../../images/slide/main-slide-2.jpg').default,
  mainSlide3: require('../../images/slide/main-slide-3.jpg').default,
  product01: require('../../images/product/product01.png').default,
  leaves: require('../../images/herb/leaves.png').default,
  doctor: require('../../images/footer/doctor.jpeg').default,

  headerShopImage: require('../../images/shops/Ouayun_Shop_Cover_1.jpg')
    .default,

  // รูปร้านค้าที่ไม่ได้ /resize-170x170/ เพราะว่ารูปพัง (ไม่รู้สามาเหตุ)
  tumrubthai: require('../../images/shops/resize-170x170/tumrubthai.jpg').default,
  labPhamacy: require('../../images/shops/resize-170x170/lab-phamacy.jpg').default,
  pAndF: require('../../images/shops/resize-170x170/p-and-f.jpg').default,
  siamdrug: require('../../images/shops/siamdrug.jpg').default,
  healthPlus: require('../../images/shops/resize-170x170/health-plus.jpg').default,
  sirivej: require('../../images/shops/sirivej.jpg').default,

  dChain: require('../../images/shops/d-chain.jpg').default,
  pharmax: require('../../images/shops/pharmax.jpg').default,
  fama: require('../../images/shops/resize-170x170/fama.jpg').default,
  petcharatPharma: require('../../images/shops/resize-170x170/petcharat-pharma.jpg').default,
  familyMart: require('../../images/shops/family-mart.jpg').default,
  xta: require('../../images/shops/resize-170x170/xta.jpg').default,

  bangkokdrugstore: require('../../images/shops/resize-170x170/bangkokdrugstore.jpg').default,
  healthUp: require('../../images/shops/health-up.jpg').default,
  saveDrug: require('../../images/shops/save-drug.jpg').default,
  pure: require('../../images/shops/resize-170x170/pure.jpg').default,
  onePharmacy: require('../../images/shops/one-pharmacy.jpg').default,
  boots: require('../../images/shops/resize-170x170/boots.jpg').default,

  goldenPlace: require('../../images/shops/golden-place.jpg').default,
  jiffy: require('../../images/shops/resize-170x170/jiffy.jpg').default,
  lotus: require('../../images/shops/lotus.jpg').default,
  theMall: require('../../images/shops/resize-170x170/the-mall.jpg').default,
  bigC: require('../../images/shops/resize-170x170/big-c.jpg').default,
  sevenEleven: require('../../images/shops/resize-170x170/7-eleven.jpg').default,

  whyUsHeaderImage: require('../../images/why-us/why-us-header-image.png')
    .default,
  whyUsImage1: require('../../images/why-us/Ouayun_About_4.jpg').default,
  whyUsImage2: require('../../images/why-us/Ouayun_About_6.jpg').default,
  whyUsImage3: require('../../images/why-us/Ouayun_About_5.jpg').default,

  aboutImage1: require('../../images/about/Ouayun_About_3.jpg').default,
  aboutImage2: require('../../images/about/Ouayun_About_1.jpg').default,

  defaultBody: require('../../images/body/Asset_body_1.svg').default,

  contactImage: require('../../images/contact/Ouayun_Map.jpg').default,

  mainHerb: require('../../images/main/ouayun_main_herb_1.png').default,
  consult: require('../../images/main/Ouayun_Consult_1.jpg').default,

  flower: require('../../images/background/flower.png').default,

  phone: require('../../images/contact/ouayun_call.svg').default,
  facebook: require('../../images/contact/ouayun_fb.svg').default,
  line: require('../../images/contact/ouayun_line.svg').default,
  qrCode: require('../../images/contact/Ouayun_QR_line_ official.png').default,
};
// Asset_body_1.svg
// Asset_body_2.svg
// Asset_body_3.svg
// Asset_body_4.svg
// Asset_body_5.svg
// Asset_body_6.svg
// Asset_body_7.svg
// Asset_body_8.svg
// Asset_body_9.svg
// Asset_body_10.svg
// Asset_body_11.svg
// Asset_body_12.svg
// Asset_body_13.svg
// Asset_body_14.svg
