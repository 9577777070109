import { palette } from './palette';

/**
 * Roles for colors.  Prefer using these over the palette.  It makes it easier
 * to change things.
 *
 * The only roles we need to place in here are the ones that span through the app.
 *
 * If you have a specific use-case, like a spinner color.  It makes more sense to
 * put that in the <Spinner /> component.
 */
export const color = {
  /**
   * The palette is available to use, but prefer using the name.
   */
  palette,
  /**
   * A helper for making something see-thru. Use sparingly as many layers of transparency
   * can cause older Android devices to slow down due to the excessive compositing required
   * by their under-powered GPUs.
   */
  transparent: 'rgba(0, 0, 0, 0)',
  transparent2: 'transparent',
  /**
   * The screen background.
   */
  backgroundPrimary: palette.mainColorBackground,
  /**
   * The screen background.
   */
  backgroundWhite: palette.white,
  /**
   * Color text of dark theme.
   */
  textWhite: palette.white,
  /**
   * Default color text
   */
  textBlack: palette.black,

  buttonGreen: palette.footerGreen,

  backgroundProductGreen: palette.lightGreen,

  footer: palette.footerGreen,

  copyright: palette.darkGreen,

  highlight: palette.lightGreen,

  darkGray: palette.darkGray,

  lightGray: palette.lightGray,

  red: palette.red,
  darkRed: palette.darkRed,

  /**
   * Default main color.
   */
  mainColor1: palette.main1,
  mainColor2: palette.main2,
  mainColor3: palette.main3,
  mainColor4: palette.main4,
  mainColor5: palette.main5,
};
