import { MutableRefObject, useEffect, useRef, useState } from 'react';

interface UseStickyProps {
  stickyRef: MutableRefObject<any>;
  sticky: boolean;
}

const useSticky = (): UseStickyProps => {
  const stickyRef = useRef<any>(null);
  const [sticky, setSticky] = useState<boolean>(false);
  const [stickyOffset, setStickyOffset] = useState<number>(0);

  useEffect(() => {
    if (!stickyRef.current) {
      return;
    }
    setStickyOffset(stickyRef.current.offsetTop);
  }, [stickyRef, setStickyOffset]);

  useEffect(() => {
    const handleScroll = () => {
      if (!stickyRef.current) {
        return;
      }
      const shouldBeSticky = window.scrollY > stickyOffset;
      setSticky(shouldBeSticky);
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [setSticky, stickyRef, stickyOffset]);

  return {
    stickyRef,
    sticky,
  };
};

export default useSticky;
