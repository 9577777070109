import { facebookOfficial } from 'react-icons-kit/fa/facebookOfficial';
import { instagram } from 'react-icons-kit/fa/instagram';

export const menuMobileItemsFooter = (t: any) => {
  return [
    {
      name: t('product').toUpperCase(),
      items: [
        {
          name: t('by_brand'),
          link: '/product',
          orderBy: 1,
        },
        {
          name: t('by_type'),
          link: '/product',
          orderBy: 2,
        },
      ],
    },
    {
      name: t('footer_virtual_doctor'),
      link: '/virtual-doctor',
    },
    {
      name: t('about'),
      link: '/about',
    },
    {
      name: t('article').toUpperCase(),
      link: '/article',
    },
    {
      name: t('policy'),
      link: '/policy',
    },
    {
      name: t('shop'),
      link: '/shop',
    },
    {
      name: t('online_store'),
      link: 'https://shop.ouayun.com/th',
      targetBlank: true,
    },
    {
      name: t('contact_us'),
      link: '/contact',
    },
  ];
};

const menuItemsFooter = (t: any) => {
  return [
    {
      col: {
        sm: 4,
      },
      items: [
        {
          name: t('product').toUpperCase(),
          link: '/product',
          fontWeight: 'bold',
        },
        {
          name: t('by_brand'),
          link: '/product',
          orderBy: 1,
        },
        {
          name: t('by_type'),
          link: '/product',
          orderBy: 2,
        },
      ],
    },
    {
      col: {
        sm: 4,
      },
      items: [
        {
          name: t('footer_virtual_doctor'),
          link: '/virtual-doctor',
        },
        {
          name: t('about'),
          link: '/about',
        },
        {
          name: t('article').toUpperCase(),
          link: '/article',
        },
        {
          name: t('policy'),
          link: '/policy',
        },
      ],
    },
    {
      col: {
        sm: 4,
      },
      items: [
        {
          name: t('shop'),
          link: '/shop',
        },
        {
          name: t('online_store'),
          link: 'https://shop.ouayun.com/th',
          targetBlank: true,
        },
        {
          name: t('contact_us'),
          link: '/contact',
        },
        {
          name: t('follow_us'),
          link: 'https://web.facebook.com/OuayUn/',
          icons: [{ icon: facebookOfficial }],
          targetBlank: true,
        },
      ],
    },
  ];
};

export default menuItemsFooter;
