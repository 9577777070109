import { types } from 'mobx-state-tree';

export const OuayUnStore = types
  .model({
    language: types.string,
    order_by_id: types.number,
    screenSize: types.maybeNull(types.model({
      height: types.number,
      width: types.number
    })),
    isAcceptedCookie: types.maybeNull(types.boolean)
  })
  .actions((self) => ({
    getLanguage(lang: string) {
      self.language = lang;
    },
    getProductOrderById(typeId: number) {
      self.order_by_id = typeId;
    },
    setScreenSize({ width, height }) {
      self.screenSize = { width, height }
    },
    setAcceptCookie(accepted: boolean) {
      self.isAcceptedCookie = accepted;
    }
  }));
