import { CSSProperties } from 'react';
import styled from 'styled-components';
import { spacing, color } from '../theme';

export const MOBILE_HEADER: CSSProperties = {
  fontSize: 40,
  lineHeight: 1,
  color: color.textBlack,
};

export const MOBILE_HEADER_TH: CSSProperties = {
  ...MOBILE_HEADER,
  fontFamily: 'ChonburiRegular',
};

export const MOBILE_HEADER_EN: CSSProperties = {
  ...MOBILE_HEADER,
  fontFamily: 'RozhaRegular',
};

export const HEADER: CSSProperties = {
  fontSize: 40,
  lineHeight: 1,
  color: color.textBlack,
};

export const HEADER_TH: CSSProperties = {
  ...HEADER,
  fontFamily: 'ChonburiRegular',
};

export const HEADER_EN: CSSProperties = {
  ...HEADER,
  fontFamily: 'RozhaRegular',
};

export const HEADER_2: CSSProperties = {
  fontSize: 25,
  lineHeight: 1.3,
  color: color.textBlack,
};

export const HEADER_2_TH: CSSProperties = {
  ...HEADER_2,
  fontFamily: 'ChonburiRegular',
};

export const HEADER_2_EN: CSSProperties = {
  ...HEADER_2,
  lineHeight: 1.1,
  fontFamily: 'RozhaRegular',
};

export const TITLE_2_TH: CSSProperties = {
  fontSize: 19,
  color: color.textBlack,
  fontFamily: 'KanitMedium',
};

export const TITLE_2_EN: CSSProperties = {
  fontSize: 17,
  color: color.textBlack,
  fontFamily: 'RobotaBold',
};

export const BODY_1_EN: CSSProperties = {
  fontSize: 13,
  color: color.textBlack,
  fontFamily: 'RobotaRegular',
};

export const BODY_1_TH: CSSProperties = {
  fontSize: 15,
  color: color.textBlack,
  fontFamily: 'KanitLight',
};

export const BODY_2: CSSProperties = {
  lineHeight: 1.5,
  color: color.textBlack,
};

export const BODY_2_TH: CSSProperties = {
  ...BODY_2,
  fontSize: 17,
  fontFamily: 'KanitLight',
};

export const BODY_2_EN: CSSProperties = {
  ...BODY_2,
  fontSize: 15,
  fontFamily: 'RobotaRegular',
};

export const BODY_3_TH: CSSProperties = {
  lineHeight: 1.4,
  fontSize: 17,
  color: 'color.textBlack',
  fontFamily: 'KanitRegular',
};

export const BODY_3_EN: CSSProperties = {
  lineHeight: 1.4,
  fontSize: 16,
  color: color.textBlack,
  fontFamily: 'RobotaBlack',
};

export const BODY_4_TH: CSSProperties = {
  fontSize: 15,
  color: '#ffffff',
  fontFamily: 'KanitLight',
};

export const BODY_4_EN: CSSProperties = {
  fontSize: 13,
  color: '#ffffff',
  fontFamily: 'RobotaRegular',
};

export const BODY_5: CSSProperties = {
  fontSize: 11,
  color: '#ffffff',
  fontFamily: 'RobotaRegular',
};

export const BODY_6: CSSProperties = {
  fontSize: 15,
  color: color.textBlack,
  fontFamily: 'KanitLight',
};

export const BODY_6_TH: CSSProperties = {
  fontSize: 15,
  color: color.textBlack,
};

export const BODY_6_EN: CSSProperties = {
  fontSize: 14,
  color: color.textBlack,
};

export const BODY_7_TH: CSSProperties = {
  fontSize: 15,
  color: color.textBlack,
  fontFamily: 'KanitLight',
};

export const BODY_7_EN: CSSProperties = {
  fontSize: 14,
  color: color.textBlack,
  fontFamily: 'RobotaRegular',
};

export const CONTENT_INDEX: CSSProperties = {
  background: `linear-gradient(180deg, ${color.backgroundWhite} 240px, ${color.backgroundPrimary} 240px)`,
};

export const CONTENT: CSSProperties = {
  backgroundColor: color.backgroundPrimary,
};

export const CENTER: CSSProperties = {
  textAlign: 'center',
};

export const SPACE_BETWEEN_SECTION: CSSProperties = {
  paddingTop: spacing[7] + spacing[7],
  marginTop: spacing[7] + spacing[7],
};

export const MOBILE_CONTENT_CARD: CSSProperties = {
  paddingLeft: 7,
  paddingRight: 7,
};

export const CONTENT_CARD: CSSProperties = {
  paddingTop: spacing[9] - 20,
  paddingBottom: spacing[9],
};

export const SpaceBetweenContent = styled.div`
  padding-top: 50px;
  @media (min-width: 576px) {
    padding-top: 100px;
  }
`;

export const SPACE_BETWEEN_CONTENT: CSSProperties = {
  paddingTop: spacing[9],
};

export const NON_PADDING_LEFT_RIGHT: CSSProperties = {
  paddingLeft: spacing[0],
  paddingRight: spacing[0],
};

export const CONTENT_CENTER: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const ImageMobileBox = styled.div`
  width: 100%;
  height: 480px;
  @media (max-width: 576px) {
    height: 360px;
  }
`;

export const IMAGE_STYLE: CSSProperties = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
};

export const ImageHorizontal = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px 80px;
  @media (min-width: 576px) {
    border-radius: 0px 110px;
  }
`;

export const LeftImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px 80px;
  @media (min-width: 576px) {
    border-radius: 0px 110px;
  }
`;

export const RightImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 80px 0px;
  @media (min-width: 576px) {
    border-radius: 110px 0px;
  }
`;

export const DIV_SHOP_IMAGE_STYLE: CSSProperties = {
  width: '100%',
  height: '100%',
  backgroundColor: color.backgroundWhite,
  padding: spacing[3],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const SHOP_IMAGE_STYLE: CSSProperties = {
  width: 'inherit',
  objectFit: 'cover',
};

export const RECOMMENDED: CSSProperties = {
  marginTop: spacing[7],
};

export const HERB_ROOT: CSSProperties = {
  paddingTop: spacing[7],
  paddingBottom: spacing[7] + spacing[7],
  backgroundColor: '#faf7e0',
  paddingLeft: spacing[7] + spacing[7],
  paddingRight: spacing[7] + spacing[7],
};

export const BUTTON_STYLE: CSSProperties = {
  padding: `${spacing[1]}px ${spacing[6]}px`,
  border: `1px solid ${color.mainColor2}`,
  backgroundColor: color.mainColor2,
  borderRadius: spacing[4],
  color: color.textWhite,
  fontSize: 15,
};

export const CARD_TEXT_IN_HERB: CSSProperties = {
  height: 360,
  backgroundColor: '#eeebd5',
};

export const CARD_TEXT_IN_HERB_CONTENT: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  height: '80%',
  paddingLeft: spacing[7],
  paddingRight: spacing[7],
};

export const CARD_TEXT_IN_HERB_FOOTER: CSSProperties = {
  height: '20%',
  textAlign: 'center',
  paddingTop: spacing[5],
};

export const TIMELINE_BOX: CSSProperties = {
  color: color.textWhite,
  padding: `${spacing[6]}px ${spacing[6]}px 45px`,
  borderRadius: `${spacing[0]}px ${spacing[8]}px`,
  marginRight: -5,
};

export const TIMELINE_BOX_LEFT: CSSProperties = {
  padding: `40px 30px 45px 30px`,
  borderRadius: `${spacing[0]}px ${spacing[8]}px`,
  marginRight: -5,
};

export const TIMELINE_BOX_RIGHT: CSSProperties = {
  padding: `40px 30px 45px 30px`,
  borderRadius: `${spacing[8]}px ${spacing[0]}px`,
  marginLeft: -5,
};

export const TIMELINE_DOT: CSSProperties = {
  paddingLeft: spacing[0],
  paddingRight: spacing[0],
  width: 10,
  height: 10,
  backgroundColor: color.mainColor4,
  border: `2px solid ${color.mainColor5}`,
  borderRadius: '50%',
  zIndex: 999,
};

export const FOOTER_IMAGE: CSSProperties = {
  width: '100%',
  objectFit: 'cover',
};

export const REMOVE_MARGIN_LEFT_RIGHT: CSSProperties = {
  marginLeft: 0,
  marginRight: 0,
};

export const CLER_SPACE: CSSProperties = {
  margin: 0,
  padding: 0,
};

export const FOOTER_LINE: CSSProperties = {
  margin: 0,
  padding: 15,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  backgroundColor: '#dbddc6',
};
