// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-article-article-id-tsx": () => import("./../../../src/pages/article/[articleId].tsx" /* webpackChunkName: "component---src-pages-article-article-id-tsx" */),
  "component---src-pages-article-index-tsx": () => import("./../../../src/pages/article/index.tsx" /* webpackChunkName: "component---src-pages-article-index-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-herbal-properties-index-tsx": () => import("./../../../src/pages/herbal-properties/index.tsx" /* webpackChunkName: "component---src-pages-herbal-properties-index-tsx" */),
  "component---src-pages-herbal-properties-properties-id-tsx": () => import("./../../../src/pages/herbal-properties/[propertiesId].tsx" /* webpackChunkName: "component---src-pages-herbal-properties-properties-id-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-pages-product-index-tsx": () => import("./../../../src/pages/product/index.tsx" /* webpackChunkName: "component---src-pages-product-index-tsx" */),
  "component---src-pages-product-product-id-tsx": () => import("./../../../src/pages/product/[productId].tsx" /* webpackChunkName: "component---src-pages-product-product-id-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-virtual-doctor-tsx": () => import("./../../../src/pages/virtual-doctor.tsx" /* webpackChunkName: "component---src-pages-virtual-doctor-tsx" */),
  "component---src-pages-why-us-tsx": () => import("./../../../src/pages/why-us.tsx" /* webpackChunkName: "component---src-pages-why-us-tsx" */)
}

