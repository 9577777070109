export const palette = {
  black: '#000000',
  white: '#FFFFFF',
  mainColorBackground: '#f7f8f3',
  buttonGreen: '#225222', // button show product detail
  lightGreen: '#98BF64', // background product
  darkGreen: '#2c3a2c', // copyright
  footerGreen: '#adc790', // footer color
  darkGray: '#dbd9d4',
  lightGray: '#e7e5e0',

  //! THEME 1
  main1: '#eeefea', // background
  main2: '#225222', // button
  main3: '#9ec081', // background image
  main4: '#9ec081', // footer
  main5: '#2c3a2c', // dark footer

  red: '#ca0112',
  darkRed: '#ac0112',

  //! THEME 2
  // main1: '#f0f9f8', // background
  // main2: '#68ad4e', // button
  // main3: '#8ec0ce', // background image
  // main4: '#437189', // footer
  // main5: '#153249', // dark footer

  //! THEME 3
  // main1: '#fff9ed', // background
  // main2: '#67350d', // button
  // main3: '#f9b896', // background image
  // main4: '#e98a58', // footer
  // main5: '#67350d', // dark footer

  //! THEME 4
  // main1: '#fffefa', // background
  // main2: '#6f806a', // button
  // main3: '#f6eee3', // background image
  // main4: '#cb8c53', // footer
  // main5: '#ffffff', // dark footer

  productSet1: {
    A1: '#f9f1ce',
    A2: '#fcf8e0',
    B1: '#fae5d2',
    B2: '#fdf2e9',
    C1: '#e8d8d2',
    C2: '#f0e5e0',
    D1: '#e0e5e8',
    D2: '#ebeef0',
    E1: '#add4db',
    E2: '#c2dfe6',
    F1: '#adc6db',
    F2: '#c2d4e6',
    G1: '#e3e8dc',
    G2: '#ecf0e6',
    H1: '#e7e8e6',
    H2: '#f0f0f0',
    I1: '#e6e8b9',
    I2: '#f1f3d4',
    J1: '#f4e1e7',
    J2: '#f7edf1',
    K1: '#f4e5cd',
    K2: '#f7efe4',
    L1: '#d6e5c6',
    L2: '#eff5e9',
  },

  productSet2: {
    A1: '#f2e4aa',
    A2: '#f9f1ce',
    B1: '#f0c9a8',
    B2: '#fae5d2',
    C1: '#d59d9e',
    C2: '#e5c4c4',
    D1: '#e0e5e8',
    D2: '#d9d0e5',
    E1: '#aed4da',
    E2: '#cfe5e9',
    F1: '#aec6da',
    F2: '#cedde8',
    G1: '#e3e8dc',
    G2: '#64c0a6',
    H1: '#eae8e6',
    H2: '#dcd9d6',
    I1: '#d6da87',
    I2: '#e8eab6',
    J1: '#d9d0e5',
    J2: '#e8e3f0',
    K1: '#eacfa4',
    K2: '#f4e5cd',
    L1: '#c1d8aa',
    L2: '#e7efdd',
  },
};
