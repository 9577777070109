import React from 'react';
import { useMst } from '@/models/root-store';
// import { PageProps } from 'gatsby';

// const NotFound: React.FC<PageProps> = () => (
const NotFound = () => {
  const { ouayUnStore } = useMst();

  return (
    <main className={'main'} style={{ minHeight: ouayUnStore?.screenSize?.height ?? 900 }}>
      {/* <p>Sorry, page not found!</p> */}
    </main>
  )
};

export default NotFound;
