import React, { useEffect, useState, useRef, cloneElement } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Header from './NavigationBar/header';
import Footer from './Footer/footer';
import NotFound from '../pages/404';
import menuItems from './menu-item';
import menuItemsFooter from './menu-item-footer';
import styled from 'styled-components';
import { match } from '@reach/router/lib/utils';
import { CONTENT_INDEX } from './styled/main';
import { Container, Row, Col } from 'react-bootstrap';
import { useMst } from '../models/root-store';
import { AcceptCookie } from '@/components/accept-cookie/accept-cookie';
import AOS from 'aos';

export const extractPathParams = (props: any): any | null => {
  if (props.pageContext.matchPath) {
    const result = match(props.pageContext.matchPath, props.location.pathname);
    if (result && result.params) {
      return result.params;
    }
  }
  return null;
};

const MainLayout: React.FC<{ pageContext: any; custom404: any }> = observer(
  ({ children, pageContext, location, custom404 }: any) => {
    const { t, i18n } = useTranslation();
    const { ouayUnStore } = useMst();
    const [screenSize, setScreenSize] = useState<any>({ height: 0, width: 0 });
    const [hasRan, setHasRan] = useState<boolean>(false);
    const [isShow, setIsShow] = useState<boolean>(false);

    useEffect(() => {
      AOS.init({
        offset: 0,
        duration: 600,
        easing: 'ease',
        once: true
      });

      // window.addEventListener('load', AOS.refresh);
      AOS.refresh();

      setTimeout(() => {
        setIsShow(true);
      }, 1000)
      return () => {
        setIsShow(false)
      }
    }, [])

    useEffect(() => {
      const { language } = ouayUnStore;
      i18n.changeLanguage(language);
    }, [ouayUnStore.language]);

    useEffect(() => {
      if (!hasRan) {
        setHasRan(true);
        updateScreenSize();
      }
      window.addEventListener('resize', updateScreenSize);
      return () => {
        window.removeEventListener('resize', updateScreenSize);
      };
    }, [screenSize]);

    const updateScreenSize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight })
      ouayUnStore.setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };

    return (
      <div style={{ position: 'relative' }}>
        <Container fluid>
          <Row>
            {/* <Col className="d-md-none d-block" style={{ height: screenSize.width <= 576 ?  }}></Col>
            <Col className="d-none d-md-block" style={{ height: 238 }}></Col> */}
            <BoxSpace />
          </Row>
        </Container>
        <Header menuLinks={menuItems(t)} />
        <div style={CONTENT_INDEX}>
          {custom404 ? (
            <NotFound />
          ) : (
            <div>
              {/* <div className="main-content">{children}</div> */}
              {cloneElement(
                children,
                extractPathParams({ location, pageContext }),
              )}
            </div>
          )}
          {/* {pageContext.layout !== 'auth' && !custom404 && <LayoutFooter>Footer</LayoutFooter>} */}
        </div>
        <Footer menuLinks={menuItemsFooter(t)} />

        {isShow && !ouayUnStore.isAcceptedCookie && <AcceptCookie onConfirm={() => ouayUnStore.setAcceptCookie(true)} />}
      </div>
    );
  },
);

export default MainLayout;

const BoxSpace = styled(Col)`
  height: 238px;
  @media (max-width: 768px) {
    height: 130px;
  }
`
