const menuItems = (t: any) => [
  // {
  //   name: t('product').toUpperCase(),
  //   link: '/product',
  // },
  {
    name: t('virtual_doctor'),
    link: '/virtual-doctor',
  },
  {
    name: t('about'),
    link: '/about',
  },
  {
    name: t('herbal_properties'),
    link: '/herbal-properties',
  },
  {
    name: t('shop'),
    link: '/shop',
  },
  {
    name: t('article').toUpperCase(),
    link: '/article',
  },
];

export default menuItems;
