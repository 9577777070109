import React, { CSSProperties, useEffect } from 'react';
import { AcceptCookieProps } from './accept-cookie.props';
import { Icon } from 'react-icons-kit';
import { arrows_circle_plus } from 'react-icons-kit/linea/arrows_circle_plus';
import { color, spacing } from '@/Layouts/theme';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const ROOT: CSSProperties = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  backgroundColor: 'rgb(246 248 242 / 93%)',
  maxWidth: 350,
  marginLeft: spacing[4],
  marginRight: spacing[4],
  marginBottom: spacing[4],
  padding: spacing[4],
  borderRadius: 5,
  color: color.textBlack,
  boxShadow: '3px 3px 6px #2c3a2c29',
};

export function AcceptCookie(props: AcceptCookieProps) {
  const { onConfirm, onCancel } = props;
  const { t } = useTranslation();

  return (
    <div style={ROOT}>
      <div>
        {/* <h5>Your privacy</h5> */}
        <Text>{t('cookies_notice')}</Text>
      </div>
      <div>
        <Button onClick={onConfirm}>{t('accept')}</Button>
      </div>
    </div>
  );
}

const Button = styled.button`
  width: 100%;
  border: 1px solid ${color.backgroundPrimary};
  border-radius: 16px;
  padding: 4px;
  font-size: 14px;
  color: ${color.textWhite};
  background-color: ${color.mainColor2};
`;

const Text = styled.p`
  text-align: justify;
  line-height: 1.3;
`;
