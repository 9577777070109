import React, { CSSProperties, useState, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import { color, images, spacing } from '../../Layouts/theme';
import { Icon } from 'react-icons-kit';
import { plus } from 'react-icons-kit/fa/plus';
import { minus } from 'react-icons-kit/fa/minus';
import { BODY_5, NON_PADDING_LEFT_RIGHT } from '../../Layouts/styled/main';
import i18next from 'i18next';
import { menuMobileItemsFooter } from '../menu-item-footer';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { useMst } from '../../models/root-store';
import LinkTo from '../../components/link/link';

interface FooterProps {
  menuLinks: Array<{
    col?: {
      xs?: number;
      sm?: number;
      md?: number;
      lg?: number;
    };
    items: Array<{
      name: string;
      link?: string;
      targetBlank?: boolean;
      fontWeight?: string;
      orderBy?: number;
      icons?: Array<{
        icon: any;
      }>;
    }>;
  }>;
}

const ROOT: CSSProperties = {
  paddingTop: 95,
  paddingBottom: 60,
};

const ROOT_NO_LINE: CSSProperties = {
  paddingTop: 80,
  paddingBottom: 60,
};

const LOGO: CSSProperties = {
  width: 150,
  height: 'auto',
};

const LINK: CSSProperties = {
  color: color.textWhite,
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
};

const FOOTER_BAR: CSSProperties = {
  width: '100%',
  paddingTop: spacing[3],
  paddingBottom: spacing[3],
  backgroundColor: color.mainColor5,
  textAlign: 'center',
};

const EXPAND_ICON: CSSProperties = {
  color: color.textWhite,
  padding: `${spacing[0]}px ${spacing[1]}px`,
};

const LEFT_RIGHT_SPACING: CSSProperties = {
  paddingLeft: 8,
  paddingRight: 8
}

const isBrowser = typeof window !== 'undefined';

const Footer: React.FC<FooterProps> = observer(({ menuLinks }) => {
  const { t } = useTranslation();
  const { ouayUnStore } = useMst();
  const [expanded, setExpanded] = useState<string | false>('');

  const pathname = isBrowser
    ? window.location.pathname.split('/').join('')
    : '';

  const menus = menuMobileItemsFooter(t);

  const handleChange = (panel: string) => (
    event: ChangeEvent<{}>,
    newExpanded: boolean,
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleClick = (typeId: number | null) => {
    if (typeId) {
      ouayUnStore.getProductOrderById(typeId);
    }
  };

  const Accordion = withStyles({
    root: {
      backgroundColor: color.mainColor4,
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
      // borderBottom: `1px solid ${color.mainColor5}`,
      padding: 0,
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

  const ICON: CSSProperties = {
    paddingLeft: spacing[3],
    marginTop: i18next.language === 'th' ? 0 : -6,
  };

  const FONT_STYLE: CSSProperties = {
    fontSize: i18next?.language === 'th' ? 17 : 15,
    fontFamily: i18next?.language === 'th' ? 'KanitLight' : 'RobotaRegular',
  };

  return (
    <Container fluid style={{ backgroundColor: color.mainColor4 }}>
      <div className="not-footer-hd">
        {/* //? for screen size xs-md */}
        <div className="d-md-none d-block">
          <Row
            className="justify-content-center"
            style={{ padding: spacing[4] }}
          >
            <img
              src={images.logoFooter}
              alt="logo-footer"
              style={{ width: 120, height: 'auto' }}
            />
          </Row>
          <Row className="justify-content-center">
            <Col xs="10" sm="8">
              {menus?.map((menu: any, idx: number) => {
                return (
                  <Accordion
                    key={`menu-${idx}`}
                    square
                    expanded={expanded === `panel${idx}`}
                    onChange={handleChange(`panel${idx}`)}
                    style={{
                      borderBottom:
                        idx + 1 === menus.length
                          ? ''
                          : `1.5px solid ${color.textWhite}`,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        menu?.items ? (
                          expanded === `panel${idx}` ? (
                            <Icon icon={minus} size={8} style={EXPAND_ICON} />
                          ) : (
                            <Icon icon={plus} size={8} style={EXPAND_ICON} />
                          )
                        ) : (
                          <></>
                        )
                      }
                      aria-controls={`panel${idx}d-content`}
                      id={`panel${idx}d-header`}
                      style={{
                        marginBottom:
                          idx === menus?.length - 1 ? 30 : undefined,
                      }}
                    >
                      <LinkTo
                        key={`${menu?.link}-${idx}`}
                        to={menu?.link}
                        style={LINK}
                        target={menu?.targetBlank ? '_blank' : ''}
                        children={
                          <div style={{ ...FONT_STYLE }}>{menu.name}</div>
                        }
                      />
                    </AccordionSummary>
                    {menu?.items && (
                      <Container fluid style={NON_PADDING_LEFT_RIGHT}>
                        <Row className="justify-content-center">
                          <Col xs="10" sm="11">
                            {menu.items.map((item: any, index: number) => {
                              return (
                                <Container
                                  fluid
                                  key={`${item.name}-${index}`}
                                  style={{ ...NON_PADDING_LEFT_RIGHT }}
                                >
                                  <Row>
                                    <Col xs="12">
                                      <LinkTo
                                        key={`${item?.link}-${index}`}
                                        to={item?.link}
                                        style={LINK}
                                        onClick={() =>
                                          handleClick(item?.orderBy || null)
                                        }
                                        children={
                                          <div
                                            style={{
                                              ...FONT_STYLE,
                                              padding:
                                                index != 0
                                                  ? `${spacing[2]}px ${spacing[0]}px`
                                                  : `${spacing[0]}px ${spacing[0]}px ${spacing[2]}px`,
                                              marginBottom:
                                                menu.items.length === index + 1
                                                  ? 12
                                                  : 0,
                                            }}
                                          >
                                            {item.name}
                                          </div>
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Container>
                              );
                            })}
                          </Col>
                        </Row>
                      </Container>
                    )}
                  </Accordion>
                );
              })}
            </Col>
          </Row>
        </div>

        {/* //? for screen size md++ */}
        <div className="d-none d-md-block">
          <Row
            className="justify-content-center"
            style={
              pathname === '' ||
                pathname.includes('product') ||
                pathname.includes('virtual-doctor')
                ? ROOT
                : ROOT_NO_LINE
            }
          >
            <Col md={{ span: 4, offset: 2 }} style={{ display: 'flex', alignItems: 'center' }}>
              <img src={images.logoFooter} alt="logo-footer" style={LOGO} />
            </Col>
            <Col md="6" style={{ paddingLeft: 45 }}>
              <Row>
                {menuLinks.map((menu: any, index: number) => {
                  return (
                    <Col key={index} {...menu.col} style={LEFT_RIGHT_SPACING}>
                      {menu.items.map((item: any, i: number) => {
                        const linkStyle: CSSProperties = {
                          ...LINK,
                          fontWeight: item?.fontWeight || 0,
                        };
                        return (
                          <LinkTo
                            key={`${item?.link}-${i}`}
                            to={item?.link}
                            style={linkStyle}
                            target={item?.targetBlank ? '_blank' : ''}
                            onClick={() => handleClick(item?.orderBy || null)}
                            children={
                              <>
                                <span
                                  style={{
                                    ...FONT_STYLE,
                                    // color:
                                    //   pathname === item?.link?.replace('/', '')
                                    //     ? color.mainColor5
                                    //     : color.textWhite,
                                    paddingTop: spacing[1],
                                    paddingBottom:
                                      i18next?.language === 'en'
                                        ? spacing[1]
                                        : 0,
                                  }}
                                >
                                  {item.name}
                                </span>
                                {item?.icons?.map((icon: any, k: number) => (
                                  <Icon key={k} icon={icon.icon} style={ICON} />
                                ))}
                              </>
                            }
                          />
                        );
                      })}
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </div>
      </div>

      <div className="footer-hd">
        <Row
          className="justify-content-center"
          style={
            pathname === '' ||
              pathname.includes('product') ||
              pathname.includes('virtual-doctor')
              ? ROOT
              : ROOT_NO_LINE
          }
        >
          <Col md="auto" style={{ width: 120 }} />
          <Col
            md="auto"
            style={{ display: 'flex', alignItems: 'center', width: 420 }}
          >
            <img src={images.logoFooter} alt="logo-footer" style={LOGO} />
          </Col>
          <Col md="auto" style={{ width: 540 }}>
            <Row>
              {menuLinks.map((menu: any, index: number) => {
                return (
                  <Col key={index} {...menu.col} style={LEFT_RIGHT_SPACING}>
                    {menu.items.map((item: any, i: number) => {
                      const linkStyle: CSSProperties = {
                        ...LINK,
                        fontWeight: item?.fontWeight || 0,
                      };
                      return (
                        <LinkTo
                          key={`${item?.link}-${i}`}
                          to={item?.link}
                          style={linkStyle}
                          target={item?.targetBlank ? '_blank' : ''}
                          onClick={() => handleClick(item?.orderBy || null)}
                          children={
                            <>
                              <span
                                style={{
                                  ...FONT_STYLE,
                                  // color:
                                  //   pathname === item?.link?.replace('/', '')
                                  //     ? color.mainColor5
                                  //     : color.textWhite,
                                  paddingTop: spacing[1],
                                  paddingBottom:
                                    i18next?.language === 'en' ? spacing[1] : 0,
                                }}
                              >
                                {item.name}
                              </span>
                              {item?.icons?.map((icon: any, k: number) => (
                                <Icon key={k} icon={icon.icon} style={ICON} />
                              ))}
                            </>
                          }
                        />
                      );
                    })}
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </div>

      <Row>
        <Col style={FOOTER_BAR}>
          <div style={BODY_5}>
            {'COPYRIGHT © 2021 OUAYUN. ALL RIGHTS RESERVED.'}
          </div>
        </Col>
      </Row>
    </Container>
  );
});

export default Footer;
